var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-breadcrumbs',{staticClass:"breadcrumbsmain",attrs:{"dark":_vm.$store.state.theme == 'dark',"data-aos":"fade-right","items":_vm.breadcrumbs,"divider":">","large":""}}),_c('FormModal',{key:_vm.modals.form.key,attrs:{"show":_vm.modals.form.show,"id":_vm.modals.form.data,"programYear":_vm.localConfig.programYear},on:{"action":($v) => _vm.modalActions($v),"swal":($v) => _vm.swalActions($v)}}),_c('DetailModal',{key:_vm.modals.detail.key,attrs:{"show":_vm.modals.detail.show,"id":_vm.modals.detail.data,"programYear":_vm.localConfig.programYear},on:{"action":($v) => _vm.modalActions($v),"swal":($v) => _vm.swalActions($v)}}),_c('div',{staticClass:"px-4"},[_c('v-alert',{attrs:{"type":"info"}},[_vm._v("Geko V2.0"),_c('br'),_vm._v("Modul Scooping Visit bisa diakses melalui menu "),_c('strong',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.$router.push({
              path: 'newScoopingVisit',
              query: {
                view: 'list',
              },
            })}}},[_vm._v("New Activities")])])],1),_c('v-data-table',{class:`${
        _vm.$store.state.theme == 'dark' ? '' : ''
      } rounded-xl elevation-6 mx-3 pa-1`,attrs:{"data-aos":"fade-up","data-aos-delay":"200","multi-sort":"","headers":_vm.table.headers,"items":_vm.table.items,"search":_vm.table.search,"loading":_vm.table.loading.show,"loading-text":_vm.table.loading.text,"footer-props":{
        itemsPerPageText: 'Jumlah Data Per Halaman',
        itemsPerPageOptions: [10, 25, 40, -1],
        showCurrentPage: true,
        showFirstLastPage: true,
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{staticClass:"my-2 mx-2 mx-lg-3 align-center"},[_c('v-text-field',{staticClass:"mx-auto mb-2 mb-lg-0",attrs:{"color":"green","hide-details":"","dense":"","outlined":"","rounded":"","label":"Pencarian","placeholder":"Start type to search...","append-icon":"mdi-magnify"},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('v-divider',{staticClass:"mx-2 d-none d-lg-block"}),_c('div',{staticClass:"mx-auto"},[(false)?_c('v-btn',{staticClass:"mr-2",attrs:{"color":"green white--text","disabled":_vm.table.loading.show || _vm.table.items.length == 0,"rounded":"","disabled":""},on:{"click":() => _vm.exportExcel()}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Export ")],1):_vm._e(),(false)?_c('v-btn',{staticClass:"pl-2",attrs:{"color":"info","rounded":""},on:{"click":() => {
                  _vm.modals.form.show = true;
                  _vm.modals.form.key += 1;
                }}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-plus-circle")]),_vm._v(" Tambah ")],1):_vm._e()],1)],1)]},proxy:true},{key:"item.no",fn:function({ index }){return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.land_area",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._utils.numberFormat(item.land_area))+" Ha ")]}},{key:"item.start_scooping_date",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._utils.dateFormat(item.start_scooping_date, "DD MMMM Y"))+" "),(item.start_scooping_date != item.end_scooping_date)?_c('span',[_vm._v(" ~ "+_vm._s(_vm._utils.dateFormat(item.end_scooping_date, "DD MMMM Y"))+" ")]):_vm._e()]}},{key:"item.potential_dusun",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._utils.numberFormat(item.potential_dusun))+" Dusun ")]}},{key:"item.status",fn:function({ item }){return [_c('v-chip',{staticClass:"white--text",attrs:{"color":_vm.getStatusColumn('bg_color', item.status)}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.getStatusColumn("icon", item.status)))]),_vm._v(" "+_vm._s(_vm.getStatusColumn("text", item.status))+" ")],1)]}},{key:"item.actions",fn:function({ item }){return undefined}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }